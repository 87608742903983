<i18n>
{
  "en": {
    "links": {
      "general": "Verification",
      "security": "Security",
      "verification": "Verification"
    }
  },
  "zh": {
    "links": {
      "general": "一般的",
      "security": "安全",
      "verification": "帐户验证"
    }
  },
  "kr": {
    "links": {
      "general": "일반",
      "security": "보안",
      "verification": "확인"
    }
  }
}
</i18n>

<template>
  <div :style="{ display: isMobile ? 'block' : 'flex' }" :class="{ dark: $store.state.Index.isDarkMode }" class="dashboard">

    <DashboardMenuContainer :backButton="true" :link-array="linkArray" v-if="!isMobile" />

    <div class="content" :class="{ mobile: isMobile }">
      <Navbar :theme="$store.state.Index.isDarkMode  ? 'dark' : 'light'" :no-logo="!isMobile"></Navbar>
      <DashboardMenuContainer :backButton="true" :link-array="linkArray" v-if="isMobile" />
      <div class="body">
        <router-view></router-view>
      </div>
      <Footer :is-dashboard="true" />
    </div>
    <notifications position="top right" />
  </div>

</template>

<script>
import Navbar from "@/components/main/Navbar";
import Footer from "@/components/main/Footer";
import {mapState} from "vuex";
import DashboardMenuContainer from "@/components/main/DashboardMenuContainer";

export default {
  name: "dashboardWallets",
  components: {
    Navbar,
    DashboardMenuContainer,
    Footer
  },
  data() {
    return {
      isHamburgerClicked: false,
      linkArray: [
        {
          title: this.$t('links.security'),
          link: 'dashboard/settings/security',
          icon: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
              "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.0102 3.5H11.9929C9.86763 3.5 8.13673 5.194 8.12657 7.284V8.627H15.8724V7.301C15.8724 5.205 14.1395 3.5 12.0102 3.5ZM17.397 7.301V8.898C19.197 9.519 20.5 11.188 20.5 13.169V17.458C20.5 19.963 18.4286 22 15.8826 22H15.8531H12.4421C12.0213 22 11.6798 21.664 11.6798 21.25C11.6798 20.836 12.0213 20.5 12.4421 20.5H15.8531C17.5586 20.5 18.9449 19.136 18.9449 17.458C18.9449 17.4214 18.9545 17.3883 18.964 17.3552C18.9681 17.3412 18.9721 17.3272 18.9754 17.313V13.169C18.9754 11.491 17.5881 10.127 15.8826 10.127H8.11742C6.41193 10.127 5.02457 11.491 5.02457 13.169V17.313C5.02782 17.3283 5.032 17.3434 5.03619 17.3584C5.04511 17.3905 5.05405 17.4226 5.05405 17.458C5.05405 19.136 6.44141 20.5 8.1469 20.5C8.56768 20.5 8.90918 20.836 8.90918 21.25C8.90918 21.664 8.56768 22 8.1469 22C8.14334 22 8.14004 21.9995 8.13673 21.999C8.13343 21.9985 8.13013 21.998 8.12657 21.998C8.12504 21.998 8.12352 21.9985 8.122 21.999C8.12047 21.9995 8.11895 22 8.11742 22C5.57139 22 3.5 19.963 3.5 17.458V13.169C3.5 11.188 4.80198 9.519 6.602 8.898V7.301C6.61521 4.363 9.03013 2 11.9898 2H12.0132C14.98 2 17.397 4.378 17.397 7.301ZM12.7621 14.2028V16.4238C12.7621 16.8378 12.4206 17.1738 11.9998 17.1738C11.579 17.1738 11.2375 16.8378 11.2375 16.4238V14.2028C11.2375 13.7888 11.579 13.4528 11.9998 13.4528C12.4206 13.4528 12.7621 13.7888 12.7621 14.2028Z\" fill=\"#808191\"/>\n" +
              "</svg>\n"
        },
        {
          title: this.$t('links.verification'),
          link: 'dashboard/settings/verification',
          icon: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
              "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.6154 2.1005L19.2579 4.36744C20.0009 4.62143 20.5 5.30542 20.5 6.0714V12.7122C20.5 14.7252 19.7519 16.6741 18.3946 18.1951C17.773 18.8921 16.9755 19.4911 15.9567 20.0271L12.3663 21.912C12.2542 21.97 12.1307 22 12.0062 22C11.8827 22 11.7592 21.97 11.647 21.912L8.04946 20.026C7.02863 19.4891 6.23008 18.8901 5.60956 18.1961C4.25018 16.6731 3.5 14.7232 3.5 12.7082C3.5 12.3022 3.83959 11.9723 4.25739 11.9723C4.67518 11.9723 5.01477 12.3022 5.01477 12.7082C5.01477 14.3702 5.6322 15.9762 6.75285 17.2321C7.25194 17.7911 7.91156 18.2811 8.76876 18.7311L12.0062 20.428L15.2364 18.7321C16.0915 18.2831 16.7512 17.7931 17.2513 17.2321C18.3699 15.9772 18.9863 14.3732 18.9863 12.7122V6.0714C18.9863 5.9294 18.8936 5.8034 18.7568 5.75641L12.1142 3.48946C12.0412 3.46446 11.9599 3.46446 11.8858 3.48946L5.24425 5.75641C5.10738 5.8034 5.01477 5.9294 5.01477 6.0714V7.97135C5.01477 8.37834 4.67518 8.70833 4.25739 8.70833C3.83959 8.70833 3.5 8.37834 3.5 7.97135V6.0714C3.5 5.30542 4.00012 4.62143 4.7431 4.36744L11.3846 2.1005C11.7818 1.9665 12.2192 1.9665 12.6154 2.1005ZM11.2862 14.3175C11.0846 14.3175 10.8931 14.2395 10.7511 14.1015L8.84121 12.2455C8.54587 11.9576 8.54587 11.4936 8.84121 11.2056C9.13758 10.9176 9.61609 10.9176 9.91143 11.2056L11.2862 12.5405L14.6842 9.23962C14.9795 8.95263 15.4591 8.95263 15.7544 9.23962C16.0497 9.52661 16.0497 9.9926 15.7544 10.2796L11.8214 14.1015C11.6793 14.2395 11.4869 14.3175 11.2862 14.3175Z\" fill=\"#808191\"/>\n" +
              "</svg>\n"
        }
      ]
    }
  },
  computed: {
    ...mapState('Index', [
      'isMobile'
    ])
  }
}
</script>

<style lang="scss" scoped>

@media screen and (max-width: 1600px) {
  .dashboard {
    .content {
      left: 240px !important;
      width: calc(100% - 240px) !important;
    }
  }
}

@media screen and (max-width: 1440px) {
  .dashboard {
    .content {
      left: 240px !important;
      width: calc(100% - 240px) !important;
    }
  }
}

.dashboard {
  margin: 0;

  &.dark {
    .content .body {
      border-color: #363b3d !important;
    }
  }
  .content {
    left: 240px;
    position: relative;
    width: calc(100% - 240px);

    &.mobile {
      left: 0 !important;
      width: 100% !important;
    }

    .body {
      border-top: 1px solid #edf0f5;
    }
  }
}

</style>